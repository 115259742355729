import { Box, Typography, Card } from '@mui/material'
import React from 'react'

const PricingCard = ({ label, price, description, src, list, forwardSrc, handleForwardClick = () => { } }) => {
    return (
        <Card
            onClick={
                handleForwardClick
            }
        >
            <Box sx={{ background: '#FEDC3D', padding: '28px', flexBasis: '330px', minHeight: '648px' }}>
                {src && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={src} style={{ height: '206px', width: '217px', objectFit: 'contain' }} />
                </div>}
                <div style={{ minHeight: '357px' }}>
                    {(label || price) &&
                        <div style={{ minHeight: '66px' }}>
                            {label && <Typography style={{ fontSize: '22px', fontWeight: '400' }}>{label}</Typography>}
                            {price && <Typography style={{ fontSize: '22px', fontWeight: '400' }}>{price}</Typography>}
                        </div>
                    }
                    <Typography style={{ fontSize: '16px', fontWeight: '300', padding: '14px 0 0' }}>{description}</Typography>
                    {list && <ul style={{ fontSize: '16px', fontWeight: '300' }}>
                        {list?.map((item) =>
                            <li>{item}</li>
                        )}
                    </ul>}
                </div>
                {forwardSrc &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <img onClick={handleForwardClick} src={forwardSrc} style={{ height: '28px', width: '28px', cursor: 'pointer' }} />
                    </div>
                }
            </Box >
        </Card>
    )
}

export default PricingCard