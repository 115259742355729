import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  Container,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import inkwellLogo from "./images/inkwell-logo.png";
import { WhiteButton } from "./components/InkwellWidgets";





import Home from "./views/Home";
import Writer from "./views/Writer";
import Dashboard from "./views/Dashboard";
import Pricing from "./views/Pricing";
import Plans from "./views/Plans"


import AuthDialog from "./components/AuthDialog";
import theme from "./theme";
import { isSubActive, createCheckoutSession, createStripePortalLink } from "./payments/Stripe";
import { getFirestore } from "@firebase/firestore";
import { firebaseApp } from "./index";
import AccountSettings from "./views/AccountSettings";
// import Plans from "./views/Plans";
import Private from "./components/router/Private";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "./redux/reducers/userReducer";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { set } from "firebase/database";

const responsiveTheme = responsiveFontSizes(theme);
const isDev = process.env.NODE_ENV === "development";

export default function App() {
  const { currentUser } = useSelector((state) => state?.root?.user);
  const location = useLocation();
  const { pathname } = location;
  const analytics = getAnalytics();
  logEvent(analytics, "app_open");
  const dispatch = useDispatch();
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [authDialogMode, setAuthDialogMode] = useState("login");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [user, setUser] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const db = getFirestore();
  const auth = getAuth();

  const [width, setWidth] = useState(window.innerWidth);


  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
      setIsMobile(window.innerWidth < 768);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const navigate = useNavigate();


  const handleSubscribe = () => {
    // Navigate to the APP INTERNAL plans page
    navigate("/plans");
  }


  const mobileWarnDialog = () => {
    return (
      <Dialog
        open={isMobile}
        onClose={() => setIsMobile(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Please use the Inkwell app on a desktop device.
          </Typography>
          <Button 
            variant="contained"
            onClick={() => setIsMobile(false)}
          >
            Continue
          </Button>
        </Box>
      </Dialog>
    );
  }


  const handleLogout = () => {
    getAuth()
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        dispatch(resetUser());
        window?.location.replace("https://inkwellpr.ai/?logout=true");
      })
      .catch(() => { });
  };




  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (newUser) => {
      if (!newUser?.email) {
        signInAnonymously(auth);
      } else {
        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser));
      }
    });
    return () => {
      listener();
    };
  }, [auth]);

  useEffect(() => {
    if (!auth?.currentUser?.email) return;
    isSubActive(db, user).then((active) => {
      console.log("Subscription active: ", active);

      setSubActive(active);
    });
  }, [auth, user, db]);


  const manageSubscription = () => {
    setIsLoading(true);
    createStripePortalLink(firebaseApp)
      .then((url) => {
        window.location.assign(url);
      })
      .catch((err) => {
        console.log("Error redirecting to stripe customer portal: ", err);
      });
  };


  const handleAuthDialogClose = () => {
    setAuthDialogOpen(false);
  };

  const openAuthDialog = (mode) => {
    setAuthDialogMode(mode);
    setAuthDialogOpen(true);
  };

  const getMode = () => {
    return authDialogMode;
  };

  return (
    <ThemeProvider theme={responsiveTheme}>
      <ToastContainer
        autoClose={3000}
        position="top-center"
      />
      <CssBaseline />
      <Container>
        {mobileWarnDialog()}

        <AuthDialog
          open={authDialogOpen}
          handleClose={handleAuthDialogClose}
          getMode={() => getMode()}
        />
        

          <AppBar position="static" sx={{ backgroundColor: "inherit" }}>
            <Toolbar variant="dense">
              <Box
                sx={{
                  mt: 2,
                  flexGrow: 1,
                }}
              >
                <Link to="https://inkwellpr.ai">
                  <img src={inkwellLogo} alt="Inkwell logo" />
                </Link>
              </Box>
              {
                user?.email ? (
                  <>
                    <Typography
                      variant="subtitle1"
                      color="text.primary"
                      style={{ marginRight: "10px", fontSize: "0.875rem" }}
                    >
                      Welcome{" "}
                      {currentUser?.firstName && currentUser?.lastName
                        ? `${currentUser?.firstName} ${currentUser?.lastName}`
                        : user?.email?.split("@")[0]}
                    </Typography>
                    <Button
                      onClick={handleLogout}
                      sx={{ color: "#555", px: 3, py: 1, fontSize: "0.875rem" }}
                    >
                      Sign out
                    </Button>

                    {subActive ? (
                      pathname !== "/account-settings" && (
                        <Link to="/account-settings">
                          <Button
                            variant="signout"
                            sx={{ color: "#555", px: 3, py: 1 }}
                          >
                            {isLoading ? (
                              <CircularProgress
                                size={20}
                                sx={{ color: "inherit" }}
                              />
                            ) : (
                              "My Account"
                            )}
                          </Button>
                        </Link>
                      )
                    ) : (
                      <Button
                        variant="text"
                        sx={{ px: 3, py: 1 }}
                        onClick={handleSubscribe}
                      >
                        {isLoading ? (
                          <CircularProgress size={20} sx={{ color: "inherit" }} />
                        ) : (
                          "Subscribe"
                        )}
                      </Button>
                    )}
                  </>
              ) : (
                <>
                  {
                  !subActive && 
                    // Plans and pricing button
                    <Button
                      variant="text"
                      sx={{ mr: 3, px: "30px", py: "10px" }}
                      onClick={
                        handleSubscribe
                      }
                    >
                      Plans & Pricing
                    </Button>
                  }
                  <WhiteButton
                    sx={{ mr: 3, px: "30px", py: "10px" }}
                    onClick={() => openAuthDialog("signup")}
                  >
                    Register
                  </WhiteButton>
                  <Button
                    variant="contained"
                    sx={{ fontWeight: "700", px: "30px", py: "10px" }}
                    onClick={() => openAuthDialog("login")}
                  >
                    Login
                  </Button>
                </>
              )}
            </Toolbar>
          </AppBar>
       

        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/:id?/:key?" element={<Home />} />
          <Route path="/try-it" element={<Writer />} />
          <Route path="/plans" element={<Plans />} />
          
          <Route element={<Private />}>

            <Route path="/writer" element={<Writer />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/manage-subscription" element={manageSubscription} />

          </Route>
          
        </Routes>
      </Container>
    </ThemeProvider>
  );
}
