import {
    query,
    collection,
    where,
    getDocs,
    addDoc,
    onSnapshot,
} from 'firebase/firestore';

import { getFunctions, httpsCallable } from 'firebase/functions';






// ====================   Get Active Products   ====================

async function fetchActiveProducts(db) {
    // Create a query to fetch active products
    const product_q = query(collection(db, 'products'), where('active', '==', true));
    const productQuerySnapshot = await getDocs(product_q);

    // Create an array of promises to fetch product info
    const productsPromises = productQuerySnapshot.docs.map(async (productDoc) => {
        let productInfo = productDoc.data();

        // Fetch prices subcollection per product
        const pricesCollection = collection(productDoc.ref, 'prices');
        const priceQuerySnapshot = await getDocs(pricesCollection);

        // Map over all price documents to extract price information
        const prices = priceQuerySnapshot.docs.map((priceDoc) => {
            return {
                priceId: priceDoc.id,
                priceInfo: priceDoc.data(),
            };
        });

        // Add the prices array to the productInfo object
        productInfo['prices'] = prices;

        return productInfo;
    });

    // Wait for all promises to resolve and return the products
    const products = await Promise.all(productsPromises);

    // Assuming there is only one product, so we can just grab the first item
    const subscription = products[0];

    return subscription;
}


// ====================   Get Price ID by Interval   ====================
export function getPriceIdByInterval(product, interval) {
    // Find the price object that matches the interval
    const price = product.prices.find((price) => price.priceInfo.interval === interval);

    // Return the price ID
    return price.priceId;
}



// ====================   Create Checkout Session   ====================
export async function createCheckoutSession(
    db,
    currentUser,
    interval,
    isOneTime = false
) {
    const INTERVALS = ['month', 'year'];
    if (!INTERVALS.includes(interval)) {
        throw new Error('Invalid interval provided: ' + interval + '. Must be one of: ' + INTERVALS.join(', '));

    }


    const subscriptionProduct = await fetchActiveProducts(db);

    // Map over the prices array to create line items for each price tier
    const lineItems = subscriptionProduct.prices
    .filter((price) => price.priceInfo.recurring.interval === interval) // Filter first by interval
    .map((price) => ({
        price: price.priceId,  // Then map to the correct format
        quantity: 1,
    }));


    // Define checkout session data
    const checkoutSessionData = {
        line_items: lineItems,
        success_url: window.location.origin,  // Can set this to a custom page
        cancel_url: window.location.origin,   // Can set this to a custom page
        mode: isOneTime ? 'payment' : 'subscription',  // Set mode based on `isOneTime`
    };

    // Add a checkout session document to Firestore
    const checkoutSessionRef = await addDoc(
        collection(db, `customers/${currentUser.uid}/checkout_sessions`),
        checkoutSessionData
    );

    // Use a Promise to listen for changes to the checkout session document
    return new Promise((resolve, reject) => {
        onSnapshot(checkoutSessionRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                reject(error);
            }
            if (url) {
                resolve(url);
            }
        });
    });
}

  
// ====================   Get Active Subscriptions   ====================

export async function isSubActive(db, currentUser) {
    const activeSub = await fetchActiveSubscriptions(db, currentUser);

    console.log('activeSub:', activeSub);




    return activeSub && activeSub['status'] === 'active';
}


async function fetchActiveSubscriptions(db, currentUser) {
    // Create a query to fetch the user's active subscriptions
    const querySubs = query(
        collection(db, 'customers', currentUser.uid, 'subscriptions'),
        where('status', 'in', ['trialing', 'active'])
    );

    // Fetch the active subscriptions
    const snapshot = await getDocs(querySubs);

    // If no active subscriptions are found, return null
    if (snapshot.empty) {
        return null; // No active subscriptions
    }

    // Assuming the user only has one active subscription at most, return the subscription data
    return snapshot.docs[0].data();
}


// ====================   Create Stripe Customer Portal Link   ====================
export async function createStripePortalLink(firebaseApp) {
    // Initialize Firebase Functions and create a portal link
    const functions = getFunctions(firebaseApp, 'us-central1');
    const createPortalLink = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
    );

    // Request Stripe to create a portal link and return the URL
    const result = await createPortalLink({
        returnUrl: window.location.origin, // You can set this to a custom page
    });

    return result.data.url;
}