import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useState } from "react";
import forwardIcon from "../images/forwardIcon.png";
import { AccountFieldLabel, SalmonDivider } from "../components/InkwellWidgets";
import AccountInput from "../components/AccountInput";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { accountSettingsSchema } from "../utils/schema/validation";
import AccountSettingsModal from "./AccountSettingsModal";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/reducers/userReducer";
import { toast } from "react-toastify";

import { createStripePortalLink } from "../payments/Stripe";

import { firebaseApp } from "..";

const AccountSettings = () => {

  const [isLoading, setIsLoading] = useState(false);
  
  const { currentUser } = useSelector((state) => state?.root?.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const db = getFirestore();
  let user = JSON.parse(localStorage.getItem("user"));
  const docRef = doc(db, "users", user?.uid);
  const [open, setOpen] = useState(false);
  const handleModal = () => {
    setOpen((pre) => !pre);
  };
  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      email: user?.email || currentUser?.email || "",
      company: currentUser?.company || "",
      jobTitle: currentUser?.jobTitle || "",
      industry: currentUser?.industry || "",
      location: currentUser?.location || "",
      marketingPermissions: currentUser?.marketingPermissions || false,
    },
    validationSchema: accountSettingsSchema,
    onSubmit: (values) => {
      updateFireBaseData(values);
    },
  });
  const updateFireBaseData = async (values) => {
    setLoader(true);
    updateDoc(docRef, values)
      .then(() => {
        updateRedux();
      })
      .catch((err) => {
        console.log(">>>HERE IS MY ERR", err)
        toast.error("Something went wrong");
      }).finally(() => {
        setLoader(false)
      });
  };
  const updateRedux = async () => {
    const docSnap = await getDoc(docRef);
    if (docSnap?.exists()) {
      dispatch(setCurrentUser(docSnap?.data()));
      toast.success("Successfuly updated");
    } else {
      toast.error("Some thing went wrong");
    }
  };

  
  const manageSubscription = () => {
    setIsLoading(true);
    createStripePortalLink(firebaseApp)
      .then((url) => {
        window.location.assign(url);
      })
      .catch((err) => {
        console.log("Error redirecting to stripe customer portal: ", err);
      });
  };

  return (
    <>
      <Box sx={{ padding: "6rem 36px 8rem" }}>
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: "400",
            paddingBottom: "14px",
          }}
        >
          Your Account
        </Typography>
        <div style={{ width: "168px" }}>
          <SalmonDivider />
        </div>
        <Box sx={{ maxWidth: "500px", padding: "12px 0 46px" }}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "300", paddingBottom: "12px" }}
          >
            Update your subscription, email and marketing preferences below.
          </Typography>
          <Typography sx={{ fontSize: "18px", fontWeight: "300" }}>
            Can’t find what you’re looking for? Just get in touch at{" "}
            <a href="mailto:hello@inkwellpr.ai" style={{ color: "inherit" }}>
              hello@inkwellpr.ai.
            </a>
          </Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik?.handleSubmit();
          }}
        >
          <Box maxWidth="500px">
            <AccountInput
              isMulti={true}
              formik={formik}
              multiProps={{
                onChange: formik?.handleChange("lastName"),
                onBlur: formik?.handleBlur("lastName"),

                value: formik?.values?.lastName,
                Inputlabel: "lastName",
                multiError:
                  formik?.errors?.lastName &&
                  formik?.touched?.lastName &&
                  formik?.errors?.lastName,
                label: "Last",
                type: "text",
                variant: "standard",
                InputLabelProps: {
                  style: {
                    color: "#808080",
                    fontSize: "14px",
                    fontWeight: "300",
                  },
                },
              }}
              Inputlabel="Name"
              label="First"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors?.firstName &&
                formik?.touched?.firstName &&
                formik?.errors?.firstName
              }
              onChange={formik?.handleChange("firstName")}
              onBlur={formik?.handleBlur("firstName")}
              value={formik?.values?.firstName}
            />
            <AccountInput
              disabled={true}
              Inputlabel="Email"
              label="Email address"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors.email &&
                formik?.touched.email &&
                formik?.errors.email
              }
              onChange={formik?.handleChange("email")}
              onBlur={formik?.handleBlur("email")}
              value={formik?.values?.email}
              dis
            />
            <AccountInput
              Inputlabel="Company"
              label="Company name"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors?.company &&
                formik?.touched?.company &&
                formik?.errors?.company
              }
              onChange={formik?.handleChange("company")}
              onBlur={formik?.handleBlur("company")}
              value={formik?.values?.company}
            />
            <AccountInput
              Inputlabel="Job title"
              label="Current job title"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors?.jobTitle &&
                formik?.touched?.jobTitle &&
                formik?.errors?.jobTitle
              }
              onChange={formik?.handleChange("jobTitle")}
              onBlur={formik?.handleBlur("jobTitle")}
              value={formik?.values?.jobTitle}
            />
            <AccountInput
              Inputlabel="Industry"
              label="Current industry"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors?.industry &&
                formik?.touched?.industry &&
                formik?.errors?.industry
              }
              onChange={formik?.handleChange("industry")}
              onBlur={formik?.handleBlur("industry")}
              value={formik?.values?.industry}
            />
            <AccountInput
              Inputlabel="Location"
              label="Current location"
              type="text"
              variant="standard"
              InputLabelProps={{
                style: {
                  color: "#808080",
                  fontSize: "14px",
                  fontWeight: "300",
                },
              }}
              error={
                formik?.errors?.location &&
                formik?.touched?.location &&
                formik?.errors?.location
              }
              onChange={formik?.handleChange("location")}
              onBlur={formik?.handleBlur("location")}
              value={formik?.values?.location}
            />
            <AccountInput
              src={forwardIcon}
              handleIconClick={handleModal}
              Inputlabel="Update password"
            />
            <AccountInput
              src={forwardIcon}
              handleIconClick={manageSubscription}
              Inputlabel="Update subscription"
            />
            <div style={{ display: "flex", alignItems: "center", gap: "39px" }}>
              <AccountFieldLabel>Marketing permissions</AccountFieldLabel>
              <Checkbox
                onChange={() => {
                  formik?.setFieldValue("marketingPermissions", !formik?.values?.marketingPermissions);
                }}
                checked={formik?.values?.marketingPermissions}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: "#FEA680",
                  },
                }}
              />
            </div>
            <div style={{ paddingTop: "20px" }}>
              <Button
                disabled={loader}
                size="large"
                variant="contained"
                type="submit"
                sx={{
                  mr: "30px",
                  mb: "10px",
                  mt: "7px",
                  fontSize: "14px",
                  fontFamily: "Signika",
                  backgroundColor: "#FEA680",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#FEA680",
                    color: "#ffffff",
                  },
                }}
              >
                {loader ? (
                  <CircularProgress size={26} sx={{ color: "#ffffff" }} />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Box>
        </form>
      </Box>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        <AccountSettingsModal handleModal={handleModal} />
      </Modal>
    </>
  );
};

export default AccountSettings;
