import React, { useEffect } from "react";
import { Link, Navigate, json, useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";
import blankRect from "../images/blank-rect.png";
import tailorsImage from "../images/home-tailors.png";
import scoresImage from "../images/home-scoring.png";
import journalistsImage from "../images/home-journalists.png";
import { TealButton } from "../components/InkwellWidgets";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import Dashboard from "./Dashboard";
import { getFirestore, getDoc, doc, setDoc } from "@firebase/firestore";
import { setCurrentUser } from "../redux/reducers/userReducer";
import { useDispatch } from "react-redux";
import { Navigation } from "@mui/icons-material";


const isDev = process.env.NODE_ENV === "development";

// log isDev
console.log("isDev", isDev);

const Home = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  const db = getFirestore();
  const auth = getAuth();
  const dispatch = useDispatch();

  const { id, key } = useParams();
  useEffect(() => {
    if (id && key) {
      const decodeEmail = atob(id);
      const decodePassword = atob(key);
      signInWithEmailAndPassword(auth, decodeEmail, decodePassword).then(
        (response) => {
          getDataFromDB(response?.user);
        }
      );
    }
  }, []);
  const getDataFromDB = async (user) => {
    const docRef = doc(db, "users", user?.uid);
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        console.log("Document data>>>SS", doc.data());
        dispatch(setCurrentUser(doc.data()));
      } else {
        createUserDoc(user)
      }
    }).catch((err) => {
      console.log("Error getting results history: ", err);
      createUserDoc(user);
    })
  };
  const createUserDoc = async (user) => {
    const docRef = doc(db, "users", user.uid);
    const docData = {
      email: user.email,
      createdAt: new Date(),
    };
    await setDoc(docRef, docData).then((res) => {
      console.log("Document written with ID: ", res);
      getDataFromDB(user)
    }).catch((error) => {
      console.error("Error adding document: ", error);
    });
  }


  const testView = (
  <Box>
    <Grid container spacing={3} sx={{ mt: "32px" }}>
      <Grid item xs={12} sm={6}>
        <Typography variant="h3" sx={{ mx: "30px", mb: "30px" }}>
          Inkwell test homepage
        </Typography>
        <Typography variant="h6" sx={{ m: "30px" }}>
          A one-of-a-kind press release tool that uses a proprietary scoring
          system — the <b>Inkwell Index</b> — to assist users in crafting
          newsworthy, high-quality press releases, regardless of your skill
          level.
        </Typography>
        <Grid container alignItems="center">
          <Grid item>
            <TealButton
              variant="outlined"
              component={Link}
              to="/writer"
              sx={{ ml: "30px", mr: "20px", px: "30px", py: "10px" }}
            >
              Get started!
            </TealButton>
          </Grid>
          <Grid item>
            <Typography>No registration required</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <img src={blankRect} width="100%" alt="Inkwell screenshot" />
      </Grid>
    </Grid>
  </Box>
  );

  const redirect = () => {
    window.location.replace("https://inkwellpr.ai/?logout=true");
  }

  const redirectToWP = (  
    !(id || key || user?.email) &&

    <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    >
      <CircularProgress size={80} sx={{ color: "#02abaa" }} />
      {redirect()}
    </div>
  );



  return (
    user?.email ?
    <Dashboard />
      :
    <Container>
      {isDev ? testView : redirectToWP}

    </Container>
  );
};

export default Home;
