import React, {
  useEffect,
  forwardRef,
  // useImperativeHandle,
  useState,
} from "react";
import { Grid } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import DoneIcon from "@mui/icons-material/Done";
import redBulb from "../images/bulb-red.png";
import yellowBulb from "../images/bulb-yellow.png";
import greenBulb from "../images/bulb-green.png";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
} from "@mui/material";
import { Translate } from "@mui/icons-material";
// import { Done } from "@mui/icons-material";

export const AccordionQuestionItem = forwardRef((props, _ref) => {
  const {
    index,
    question,
    placeholder,
    answer,
    shouldShowFeedback,
    feedback,
    expanded,
    handleChangeExpanded,
    handleChangeText,
    getAnswerFeedback,
    // updateNewsworthinessScore,
    prevQuestion,
    nextQuestion,
  } = props;

  const hasAnswer = answer && answer.length > 1;

  const scoreImage = {
    1: redBulb,
    2: redBulb,
    3: redBulb,
    4: yellowBulb,
    5: yellowBulb,
    6: yellowBulb,
    7: greenBulb,
    8: greenBulb,
    9: greenBulb,
    10: greenBulb,
  };

  // const [feedback, setFeedback] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  // const [newsworthinessScore, setNewsworthinessScore] = useState(0);

  const textInput = React.createRef();
  // console.log(question, JSON.stringify(feedback, null, 2));
  useEffect(() => {
    if (!shouldShowFeedback || answer.length < 3 || feedbackLoading) return;
    const getData = setTimeout(() => {
      setFeedbackLoading(true);
      getAnswerFeedback(question)
        .then((result) => {
          setFeedbackLoading(false);
        })
        .catch((err) => {
          console.log("Error getting feedback: ", err);
          // todo retry by updating the answer
          setFeedbackLoading(false);
        });
    }, 2000);

    return () => clearTimeout(getData);
  }, [answer]);

  useEffect(() => {
    if (expanded) focusTextInput();
  }, [expanded]);

  const handleKeyDown = (event) => {
    if ((event.key === "Tab" || event.key === "Enter") && !event.shiftKey) {
      event.preventDefault();
      nextQuestion();
    } else if (event.key === "Tab" && event.shiftKey) {
      event.preventDefault();
      prevQuestion();
    }
  };

  const focusTextInput = () => {
    textInput.current.focus();
    const strLength = textInput.current.value.length * 2;
    textInput.current.setSelectionRange(strLength, strLength);
  };

  return (
    <Accordion
      sx={{
        // transform: expanded ? "translate(-3px, -3px)" : "none",
        boxShadow: expanded ? "2px 2px 3px 3px #0002" : "none",
        border: expanded ? "1px solid #9c9c9c" : "1px solid #F5F5F5",
        mb: "10px",
        backgroundColor: "#F5F5F5",
      }}
      key={index}
      expanded={expanded}
      onChange={() => handleChangeExpanded(index)}
    >
      <AccordionSummary>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item container wrap="nowrap" spacing={2}>
            <Grid item>
              <Typography
                variant="body1"
                sx={{
                  marginLeft: "12px",
                  fontWeight: hasAnswer ? "200" : "600",
                  fontSize: "14px",
                }}
              >
                {question}
                {hasAnswer && (
                  <DoneIcon
                    fontSize="small"
                    sx={{ color: "green", ml: "3px", pt: "5px" }}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item></Grid>
          <Grid item>
            {answer.length > 1 && shouldShowFeedback && feedbackLoading && (
              <CachedIcon
                size="small"
                color="disabled"
                sx={{
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }}
              />
            )}
            {feedback?.score && shouldShowFeedback && !feedbackLoading && (
              <img
                src={scoreImage[feedback.score]}
                alt={`Score ${feedback.score}`}
              />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          sx={{
            marginLeft: "10px",
            fontWeight: "400",
            fontSize: "14px",
            fontFamily: "Signika",
          }}
          inputProps={{
            sx: { fontWeight: "400", fontSize: "14px", fontFamily: "Signika" },
          }}
          name={question}
          placeholder={placeholder}
          variant="standard"
          fullWidth={true}
          multiline
          minRows={3}
          maxRows={99}
          type="string"
          value={answer}
          helperText={
            feedback?.suggestions
              ? `Feedback: ${feedback?.suggestions}`
              : answer
                ? placeholder
                : ""
          }
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
          inputRef={textInput}
        />
      </AccordionDetails>
    </Accordion>
  );
});
