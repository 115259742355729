import React, { useState, useEffect, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { createCheckoutSession, isSubActive } from "../payments/Stripe";

import {
  Container,
  Typography,
  Button,
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EditNoteIcon from "@mui/icons-material/EditNote";

import { SalmonDivider, PopCard } from "../components/InkwellWidgets";
import { AddOutlined } from "@mui/icons-material";
import Markdown from "react-markdown";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-GB");



const FREE_LIMIT = 3;
const SUBSCRIBED_LIMIT = 24;




export default function Dashboard() {
  const auth = getAuth();
  const db = getFirestore();
  let user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [resultsHistory, setResultsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subActive, setSubActive] = useState(false);


  const handleSubscribe = () => {
    createCheckoutSession(db, auth.currentUser).then((checkoutSession) => {
        window.location.assign(checkoutSession);
    }).catch((err) => {
        console.log("Error creating checkout session: ", err);
    });
  };


  const loadSavedReleases = useCallback(
    (isSubActive) => {
      setIsLoading(true);
      const historyLimit = isSubActive ? SUBSCRIBED_LIMIT : FREE_LIMIT;

      const docRef = doc(db, "users", user?.uid);
      getDoc(docRef)
        .then((doc) => {
          if (doc.exists()) {
            const history = doc.data().resultsHistory || [];

            setResultsHistory(history.reverse().slice(0, historyLimit));
          }
        })
        .catch((err) => {
          console.log("Error getting results history: ", err);
          // todo retry by updating the answer
        }).finally(() => {
          setIsLoading(false)
        });
    },
    [db, user?.uid]
  );

  useEffect(() => {
    if (!user?.email) return;

    isSubActive(db, user)
      .then((active) => {
        loadSavedReleases(active);
        setSubActive(active);
      })
      .catch((err) => {
        console.log("Error getting active subscription: ", err);
      });
  }, [db, user?.email]);

  const handleSelect = (index) => {
    const actualIndex = resultsHistory.length - index - 1;
    navigate("/writer", { state: { savedIndex: actualIndex } });
  };

  const handleStart = (index) => {
    navigate("/writer");
  };


  const showReleaseCard = (result, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <PopCard
        onClick={() => handleSelect(index)}
        variant="outlined"
        sx={{ height: 340 }}
      >
        <CardHeader
          title={result.contentType}
          sx={{
            fontFamily: "Intro",
            fontWeight: "600",
            fontSize: "20px",
            pb: "0px",
          }}
        />
        <CardContent sx={{ py: "0px" }}>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            {timeAgo.format(result.createdAt.toDate())}
          </Typography>
          <Typography variant="body1" sx={{ mb: "16px" }}>
            <b>{result.contentType}</b>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              height: "6lh",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            <i>
              <Markdown>
                {result.result}
              </Markdown>
            </i>
          </Typography>
        </CardContent>

        <CardActions
          sx={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <EditNoteIcon sx={{ color: "#EEA27A", p: 1, fontSize: 60 }} />
          {/* <Button
            sx={{ color: "#EEA27A" }}
            size="30px"
            onClick={() => handleSelect(index)}
            endIcon={}
          >
          </Button> */}
          </CardActions>
        </PopCard>
      </Grid>
    );

  const showLoadingCard = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ width: "100%", height: "10lh"}}>
          <CircularProgress size={20} sx={{ color: "primary" }} />
        </Card>
      </Grid>
    );
  };

  const showSubscribeCard = () => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <PopCard
          variant="outlined"
          onClick={handleSubscribe}
          sx={{ height: 340 }}
        >
          <CardHeader
            title="Subscribe to Inkwell"
            sx={{
              fontFamily: "Intro",
              fontWeight: "600",
              fontSize: "20px",
              pb: "0px",
            }}
          />
          <CardContent sx={{ py: "0px" }}>
            <Typography variant="body1" sx={{ mb: "16px" }}>
              To access your full release history.
            </Typography>
            <Typography variant="body1">
              <i>Subscribe today, cancel anytime.</i>
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ color: "#EEA27A" }}
              size="large"
              onClick={() => {
                handleSubscribe()
              }}
              endIcon={<ArrowCircleRightIcon sx={{ mb: "5px" }} />}
            >
              Subscribe
            </Button>
          </CardActions>
        </PopCard>
      </Grid>
    );
  };

  const showLoaderCard = () => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <Card
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} sx={{ color: "#02abaa" }} />
        </Card>
      </Grid>
    );
  };

  return (
    <Container>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="stretch"
        mt={8}
        mb={24}
        spacing={3}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              boxShadow: "none",
            }}
          >
            <CardHeader
              title="Your saved releases"
              sx={{
                fontWeight: "800",
                fontSize: "22px",
              }}
            />
            <SalmonDivider/>
            <CardContent>
              <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 300 }}>
                Here are your saved press releases, which you can revisit or
                duplicate as required.
              </Typography>
              <Typography variant="body1" style={{ fontSize: '18px', fontWeight: 300 }}>
                Click the "Start New" link to create a new one.
              </Typography>
            </CardContent>
          </Card>
        </Grid>


        {isLoading ? showLoaderCard() : resultsHistory && !!resultsHistory?.length
          ? resultsHistory?.map(showReleaseCard)
          : <></>}

        {/* Prompt to subscribe */}
        {!subActive &&
          resultsHistory?.length >= FREE_LIMIT &&
          showSubscribeCard()}

        
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="outlined"
            disabled={resultsHistory?.length >= 24}
            onClick={handleStart}
            startIcon={<AddOutlined />}
            sx={{
              width: "100%",
              height: 340,
              borderColor: "#fffc",
              color: "primary",
              borderRadius: "2px",
              fontSize: 22,
              border: "1px solid #E3E3E3",
            }}
          >
            Start a new release
          </Button>
        </Grid>



      </Grid>
    </Container>
  );
}
