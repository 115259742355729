import { Box, CircularProgress, Grid, Typography, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { SalmonDivider } from '../components/InkwellWidgets'
import PricingCard from '../components/PricingCard'
import bussniessIcon from '../images/buissnessPlanIcon.png';
import journalistsIcon from '../images/journalist.png';
import indexScoring from '../images/indexScoring.png';
import forwardIcon from '../images/forwardIcon.png';
import { createCheckoutSession, createStripePortalLink } from '../payments/Stripe';
import { firebaseApp } from '..';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const Plans = () => {
    

    const [isPortalLoading, setIsPortalLoading] = useState(false);  


    const currentUser = getAuth().currentUser;

    const db = getFirestore();


    const handleSubscribe = (interval) => () => {
        console.log(interval);

        createCheckoutSession(db, currentUser, interval).then((checkoutSession) => {
            window.location.assign(checkoutSession);
        }).catch((err) => {
            console.log("Error creating checkout session: ", err);
        });
    }


    const freePlanServices = [
        'Saves your company information',
        'Saves your key messages',
        'Saves up to three releases (you can write over for any additional releases)',
        'One login'
    ]

    const standardCreatorPlanServices = [
        'Save up to 24 releases',
        'Early access to new features',
        'Automatic inclusion of beta features'
    ];

    const manageSubscription = () => {
        setIsPortalLoading(true);
        createStripePortalLink(firebaseApp).then((url) => {
            setIsPortalLoading(false)
            window.location.assign(url);
        }).catch((err) => {
            setIsPortalLoading(false)
            console.log("Error redirecting to stripe customer portal: ", err);
        });
    }


    return (
        <>
            <Box sx={{ padding: '6rem 36px 8rem' }}>
                <Typography
                    sx={{
                        fontSize: '32px',
                        fontWeight: '400',
                        paddingBottom: '14px'

                    }}
                >
                    Pricing
                </Typography>
                <div style={{ width: '168px' }}>
                    <SalmonDivider />
                </div>
                <Box sx={{ maxWidth: '550px', padding: '12px 0 46px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: '300', paddingBottom: '12px' }}>
                        We offer something for everyone, so you can find the way you want to work with Inkwell to best suit your needs.
                    </Typography>
                    <Typography sx={{ fontSize: '18px', fontWeight: '300', }}>
                        We are in beta and so will be optimising and iterating the platform based on feedback and will update features for each level accordingly.
                    </Typography>
                </Box>

                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="stretch"
                    mt={8}
                    mb={24}
                    spacing={3}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <PricingCard
                            src={bussniessIcon}
                            label='Always Free '
                            price='£0'
                            description='Whether you are just trying on for size or only require the occasional press release, you can have access to Inkwell forever for free, which includes:'
                            list={freePlanServices}
                            forwardSrc={forwardIcon}
                            handleForwardClick={() => {}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PricingCard
                            src={indexScoring}
                            label='Standard Creator'
                            price='9.99 monthly'
                            description='Our Standard Creator plan is perfect for Freelancers or Small to Medium businesses and allows you to expand your use of Inkwell and includes everything in the Always Free plan as well as:'
                            list={standardCreatorPlanServices}
                            forwardSrc={forwardIcon}
                            handleForwardClick={handleSubscribe("month")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PricingCard
                            src={indexScoring}
                            label='Standard Creator'
                            price='99.99 yearly'
                            description='Save on the standard creator plan with our annual billing option'
                            list={standardCreatorPlanServices}
                            forwardSrc={forwardIcon}
                            handleForwardClick={handleSubscribe("year")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <PricingCard
                            src={journalistsIcon}
                            label='Enterprise Pro'
                            description={<div>
                                If you are interested in using Inkwell for an agency or larger organisation, <strong>please get in touch directly</strong> to discuss options.
                            </div>}
                            forwardSrc={forwardIcon}
                            handleForwardClick={() => window.location.assign('mailto:hello@inkwellpr.ai')}
                        />
                    </Grid>
                    {isPortalLoading &&
                        <div style={{ position: 'absolute', width: '100%', height: '100%', top: '50%', display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress size={50} sx={{ color: "inherit" }} />
                        </div>
                    }
                </Grid>
            </Box>
        </>
    )
}

export default Plans