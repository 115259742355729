import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import AccountInput from "../components/AccountInput";
import { Alert, Box, Button, Modal, Snackbar, CircularProgress } from "@mui/material";
import { passwordSchema } from "../utils/schema/validation";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider
} from 'firebase/auth';
import { toast } from "react-toastify";
const AccountSettingsModal = ({ handleModal }) => {
  const [loader, setLoader] = useState(false)
  const auth = getAuth();
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: passwordSchema,
    onSubmit: (values) => {
      updatePasswordWithCredentials(values)
    },
  });
  const updatePasswordWithCredentials = async (values) => {
    setLoader(true)
    const oldPassword = values?.currentPassword;
    const credential = EmailAuthProvider.credential(
      auth?.currentUser?.email,
      oldPassword
    );
    reauthenticateWithCredential(auth.currentUser, credential).then((response) => {
      updatePassword(auth.currentUser, values?.newPassword);
      toast.success("Password updated successfully");
      handleModal();
    }).catch((error) => {
      const authError = error?.message?.split('/')[1];
      toast.error(`${authError?.replace(')', '')}`);
    }).finally(() => {
      setLoader(false)
    });
  };
  return (
    <Box
      sx={{
        backgroundColor: "white",
        outline: "none",
        padding: "60px",

        width: "500px",
        borderRadius: "10px",
      }}
    >
      <div style={{ paddingBottom: "50px", fontSize: "24px" }}>
        Update Password
      </div>
      <form onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit()
      }}>
        <div style={{ width: "100%", padding: "0 16px" }}>
          <AccountInput
            labelPadding="0"
            alignItems="flex-start"
            gap="0"
            flexDirection="column"
            Inputlabel="Current Password"
            type="password"
            variant="standard"
            InputLabelProps={{
              style: {
                color: "#808080",
                fontSize: "14px",
                fontWeight: "300",
              },
            }}
            error={
              formik?.errors?.currentPassword &&
              formik?.touched?.currentPassword &&
              formik?.errors?.currentPassword
            }
            onChange={formik?.handleChange("currentPassword")}
            onBlur={formik?.handleBlur("currentPassword")}
            value={formik?.values?.currentPassword}
          />
          <AccountInput
            labelPadding="0"
            alignItems="flex-start"
            gap="0"
            flexDirection="column"
            Inputlabel="New Password"
            type="password"
            variant="standard"
            InputLabelProps={{
              style: {
                color: "#808080",
                fontSize: "14px",
                fontWeight: "300",
              },
            }}
            error={
              formik?.errors?.newPassword &&
              formik?.touched?.newPassword &&
              formik?.errors?.newPassword
            }
            onChange={formik?.handleChange("newPassword")}
            onBlur={formik?.handleBlur("newPassword")}
            value={formik?.values?.newPassword}
          />
          <AccountInput
            labelPadding="0"
            alignItems="flex-start"
            gap="0"
            flexDirection="column"
            Inputlabel="Confirm New Password"
            type="password"
            variant="standard"
            InputLabelProps={{
              style: {
                color: "#808080",
                fontSize: "14px",
                fontWeight: "300",
              },
            }}
            error={
              formik?.errors?.confirmNewPassword &&
              formik?.touched?.confirmNewPassword &&
              formik?.errors?.confirmNewPassword
            }
            onChange={formik?.handleChange("confirmNewPassword")}
            onBlur={formik?.handleBlur("confirmNewPassword")}
            value={formik?.values?.confirmNewPassword}
          />
          <div
            style={{
              paddingTop: "10px",
              justifyContent: "flex-end",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Button
              size="large"
              variant="contained"
              type="button"
              onClick={handleModal}
              sx={{
                mr: "20px",
                mb: "10px",
                mt: "7px",
                fontSize: "14px",
                fontFamily: "Signika",
                backgroundColor: "#02abaa",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#02abaa",
                  color: "#ffffff",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loader}
              size="large"
              variant="contained"
              type="submit"
              sx={{
                mr: "1px",
                mb: "10px",
                mt: "7px",
                fontSize: "14px",
                fontFamily: "Signika",
                backgroundColor: "#FEA680",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#FEA680",
                  color: "#ffffff",
                },
              }}
            >

              {loader ? (
                <CircularProgress size={26} sx={{ color: "#ffffff" }} />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default AccountSettingsModal;
