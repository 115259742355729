import React from "react";
import { AccountFieldLabel } from "./InkwellWidgets";
import { TextField, useMediaQuery } from "@mui/material";

const AccountInput = ({
  flexDirection,
  labelPadding,
  alignItems,
  gap,
  Inputlabel,
  src = "",
  error,
  handleIconClick = () => {},
  isMulti = false,
  multiProps,
  ...props
}) => {
  const responsiveScreen = useMediaQuery("(max-width:767px)");
  return (
    <>
      <div
        style={{
          flexWrap: responsiveScreen ? "wrap" : "unset",
          display: "flex",
          alignItems: alignItems ? alignItems : "center",
          flexDirection: flexDirection ? flexDirection : "row",
          gap: gap ? gap : "18px",
          marginBottom: src ? "12px" : "0px",
        }}
      >
        {Inputlabel && (
          <AccountFieldLabel
            sx={{
              minWidth: "206px",
              padding: labelPadding ? labelPadding : "14px 0",
            }}
          >
            {Inputlabel}
          </AccountFieldLabel>
        )}
        {src ? (
          <img
            style={{ cursor: "pointer" }}
            onClick={handleIconClick}
            src={src}
            width="22px"
            height="22px"
          />
        ) : (
          <>
            <div style={{ width: "100%", position: "relative" }}>
              <TextField
                color={error ? "error" : ""}
                sx={{ width: "100%", m: 1, margin: "0 0 28px 0" }}
                {...props}
              />
              {!!error && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "8px",
                    fontSize: "10px",
                    color: "red",
                  }}
                >
                  {error}
                </div>
              )}
            </div>
            {isMulti && (
              <div style={{ width: "100%", position: "relative" }}>
                <TextField
                  color={multiProps?.multiError ? "error" : ""}
                  sx={{ width: "100%", m: 1, margin: "0 0 28px 0" }}
                  {...multiProps}
                />
                {!!multiProps?.multiError && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      fontSize: "10px",
                      color: "red",
                    }}
                  >
                    {multiProps?.multiError}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AccountInput;
