import React from "react";
import { Link } from "react-router-dom";
import {
    Box, Button, Grid, Typography, Container, CardHeader, List,
    CardContent, Card, CardActions, ListItem, ListItemText
} from "@mui/material";
import blankRect from "../images/blank-rect.png";
import tailorsImage from "../images/home-tailors.png";
import scoresImage from "../images/home-scoring.png";
import journalistsImage from "../images/home-journalists.png";
import { PopCard, TealButton } from "../components/InkwellWidgets";
import { SalmonDivider } from "../components/InkwellWidgets";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Title } from "@mui/icons-material";



const Pricing = () => {
    return (
        <Box>
            <Grid container alignItems="center" >
                <Grid item xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            boxShadow: "none",
                            backgroundColor: "#F7D950",
                        }}
                    >
                        <CardHeader
                            title="Your saved releases"
                            sx={{
                                fontFamily: "Intro",
                                fontWeight: "800",
                                fontSize: "22px",
                            }}
                        />
                        <SalmonDivider />
                        <CardContent>
                            <Typography variant="body1">
                                We offer something for everone, so you can find the way you want to work with Inkwell to best suit your needs.
                            </Typography>
                            <Typography variant="body1">
                                We are in beta and so will be optimising and iterating the platform based on your feedback, and will update features for each level accordingly.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {/* SPACER */}
                </Grid>
            </Grid>

            <Grid container >

                <Grid item xs={12} sm={4} textAlign="center">
                    <img src={tailorsImage} alt="tailors" />

                </Grid>
                <Grid item xs={12} sm={4} textAlign="center">
                    <img src={scoresImage} alt="scores" />
                </Grid>
                <Grid item xs={12} sm={4} textAlign="center">
                    <img src={journalistsImage} alt="journalists" />
                </Grid>
            </Grid>
            <Grid container sx={{ mb: 20 }}>
                <Grid item xs={12} sm={4} px={3}>
                    <PopCard
                        sx={{ height: "100%" }}
                    >
                        <CardHeader
                            title="Always Free"
                        />
                        <CardHeader

                            title="£0"
                        />
                        <CardContent>
                            <Typography variant="body1">
                                Whether you are just trying on for size or only require the occasional press release, you can have access to Inkwell forever for free, which includes:

                            </Typography>
                            <List>
                                <ListItem> - Saves your company information</ListItem>
                                <ListItem> - Saves your key messages</ListItem>
                                <ListItem> - Saves up to three releases (you can write over for any additional releases)</ListItem>
                                <ListItem>One login</ListItem>
                            </List>

                        </CardContent>

                        <CardActions
                            sx={{
                                mt: "auto",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
                        >
                            <Button
                                sx={{ color: "#EEA27A" }}
                                size="large"
                                onClick={() => console.log("clicked")}
                                endIcon={<ArrowCircleRightIcon sx={{ mb: "5px" }} />}
                            >
                                Select
                            </Button>
                        </CardActions>


                    </PopCard>
                </Grid>
                <Grid item xs={12} sm={4} px={3}>
                    <PopCard
                        sx={{ height: "100%" }}
                    >
                        <CardHeader
                            title="Standard Creator"
                        />
                        <CardHeader

                            title="£9.99 month / £99.99 yr"
                        />
                        <CardContent>
                            <Typography variant="body1">
                                Our Standard Creator plan is perfect for Freelancers or Small to Medium businesses and allows you to expand your use of Inkwell and includes everything in the Always Free plan as well as:

                            </Typography>
                            <List>
                                <ListItem> - Save up to 24 releases</ListItem>
                                <ListItem> - Early access to new features</ListItem>
                                <ListItem> - Automatic inclusion of beta features</ListItem>
                            </List>
                        </CardContent>
                        <CardActions sx={{
                            mt: "auto",
                            display: "flex",
                            justifyContent: "flex-end"

                        }}>
                            <Button
                                sx={{ color: "#EEA27A" }}
                                size="large"
                                onClick={() => console.log("clicked")}
                                endIcon={<ArrowCircleRightIcon sx={{ mb: "5px" }} />}
                            >
                                Select
                            </Button>
                        </CardActions>

                    </PopCard>
                </Grid>
                <Grid item xs={12} sm={4} px={3}>
                    <PopCard
                        sx={{ height: "100%" }}
                    >
                        <CardHeader
                            title="Enterprise Pro"
                        />
                        <CardContent>
                            <Typography variant="body1">
                                If you are interested in using Inkwell for an agency or larger organisation, <b>please get in touch directly</b> to discuss options.
                            </Typography>
                        </CardContent>
                    </PopCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Pricing;