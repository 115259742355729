import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";

import "./fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const firebaseConfig = {
  apiKey: "AIzaSyDwBAsVVs7UpnJo2OY4vziYz57UMmdoIx0",
  authDomain: "inkwell-9a675.firebaseapp.com",
  projectId: "inkwell-9a675",
  storageBucket: "inkwell-9a675.appspot.com",
  messagingSenderId: "951435909583",
  appId: "1:951435909583:web:20c68d0e2cede20a995aba",
  measurementId: "G-Z8ZT8P85MJ",
};

export const firebaseApp = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
