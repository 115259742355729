import { createTheme } from "@mui/material/styles";

// old theme color #F7D950

const theme = createTheme({
  typography: {
    fontFamily: "Signika",
    textTransform: "none",
    fontColor: "black",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    background: {
      default: "white",
      secondary: "#D9D9D9",
    },
    primary: {
      main: "#01ABAA",
      contrastText: "#F5F5F5",
    },
    secondary: {
      main: "#EEA27A",
    },
  },
  shadows: Array(25).fill("none"),
  shape: {
    borderRadius: 2,
  }
});

export default theme;
