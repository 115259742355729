import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

const isDev = process.env.NODE_ENV === "development";

const Private = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  console.log("isDev", isDev);

  if (isDev) {
    return user? <Outlet /> : <Navigate to="/" />;
  }

  const handleRedirect = () => {
    window.location.replace("https://inkwellpr.ai/?logout=true");
  };


  return (user?.email || user === false) ? <Outlet /> : 
    <>{handleRedirect()}</>;
};

export default Private;