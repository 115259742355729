import * as Yup from "yup";
export const accountSettingsSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
});
export const passwordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Required"),
  newPassword: Yup.string().required("New password required"),
  confirmNewPassword: Yup.string()
    .required("Confirm password required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match"),
});
